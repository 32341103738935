<template>
  <BaseCell
    class="header-cell text-p5"
    :class="{ 'is-child': isChild, 'is-sortable': isSortable }"
    v-bind="$attrs"
    is-header
    @click="toggleSort"
  >
    <slot />
    <div :class="contentClassNames">
      <span v-if="label" class="header-cell__label">{{ label }}</span>
      <BaseIcon
        v-if="isSortable"
        :class="iconClassNames"
        data-testid="header-sort-icon"
        :colors="iconColors"
        :icon="icon"
        width="13"
        height="13"
      />
      <ButtonIconContextual
        v-if="hasTooltip"
        class="header-cell__icon"
        icon="EF0080"
        label="Ver sobre"
        size="extra-small"
        @click="handleTooltipClick"
      />
    </div>
  </BaseCell>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  InjectReactive,
  Prop,
  Vue
} from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import {
  Align,
  DataTableStore,
  Sort,
  ToooltipOptions
} from '../DataTableProps';
import BaseCell from './BaseCell.vue';
import { ButtonIconContextual } from '@/components/button-icon';

@Component({
  name: 'HeaderCell',
  components: { BaseCell, BaseIcon, ButtonIconContextual }
})
export default class HeaderCell extends Vue {
  @InjectReactive('dataTableStore')
  readonly dataTableStore!: DataTableStore;

  @Prop({ required: false, type: Number, default: -1 })
  readonly index!: number;

  @Prop({ required: false, type: Boolean, default: false })
  readonly isChild!: boolean;

  @Prop({ required: false, type: String, default: '' })
  readonly label!: string;

  @Prop({ required: false, type: [String, Function], default: undefined })
  readonly sort!: Sort;

  @Prop({ required: false, type: Object })
  readonly tooltip?: ToooltipOptions;

  @Prop({ required: false, type: String, default: Align.Left })
  readonly align!: Align;

  isOpen = false;

  @Emit('on-tooltip-click')
  emitOnTooltipClick() {
    return this.index;
  }

  get isSortable() {
    return Boolean(this.sort);
  }

  get hasTooltip() {
    return Boolean(this.tooltip);
  }

  get iconClassNames() {
    return ['header-cell__icon', { 'is-active': this.isActiveSortable }];
  }

  get contentClassNames() {
    return [
      'header-cell__content',
      {
        'header-cell__content--left': this.align === Align.Left,
        'header-cell__content--center': this.align === Align.Center,
        'header-cell__content--right': this.align === Align.Right
      }
    ];
  }

  get iconColors() {
    return this.isActiveSortable
      ? NebraskaColors.textPrimary
      : NebraskaColors.textDisabled;
  }

  private get isActiveSortable() {
    return this.index === this.dataTableStore.activeSortableHeaderIndex;
  }

  get icon() {
    if (!this.isActiveSortable) return 'ic_filter_ios';
    return this.dataTableStore.isActiveSortableHeaderAsc
      ? 'ic_arrow_down'
      : 'ic_arrow_up';
  }

  handleTooltipClick(evt: Event) {
    evt.stopPropagation();
    this.emitOnTooltipClick();
  }

  private toggleSort() {
    if (this.dataTableStore.isLoading || !this.isSortable) return;

    let isAscOrder = false;
    let index = this.index;

    if (!this.isActiveSortable) {
      isAscOrder = true;
    } else if (this.dataTableStore.isActiveSortableHeaderAsc) {
      isAscOrder = false;
    } else {
      isAscOrder = true;
      index = -1;
    }

    this.dataTableStore.setSortableFilter(index, isAscOrder, this.sort);
  }
}
</script>

<style lang="less" scoped>
.header-cell {
  font-weight: bolder;
  border-bottom: @size-border-x400 solid @divider-primary;
  color: @text-primary;
  vertical-align: middle;
  user-select: none;
  &.cell {
    padding: @size-spacing-x400;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__label {
    display: inline;
  }
  &.is-child {
    color: @text-secondary;
  }
  &.is-sortable {
    cursor: pointer;
  }
  &__icon {
    margin-left: @size-spacing-x150;
  }
}
</style>

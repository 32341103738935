<template>
  <ButtonLabel
    :class="computedClassName"
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :colors="computedColors"
    :size="size"
    :icon="icon"
    :icon-position="iconPosition"
    data-testid="button-primary"
    v-on="$listeners"
  >
    {{ label }}
  </ButtonLabel>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonLabel from '@/foundation/button-label/ButtonLabel.vue';
import {
  ButtonApplication,
  ButtonSizes,
  IconPosition
} from '@/foundation/types';
import { primaryColors, primaryOverImage } from '@/styles/Button';
import { ButtonColors } from '@/foundation/models/Button';

@Component({
  name: 'ButtonPrimary',
  components: { ButtonLabel }
})
export default class ButtonPrimary extends Vue {
  /**
   * O rótulo do botão
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean })
  readonly disabled?: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean })
  readonly loading?: boolean;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number })
  readonly tabindex?: number;

  /**
   * Define o tamanho do botão
   * @values regular, large
   */
  @Prop({ type: String, default: ButtonSizes.REGULAR })
  readonly size!: ButtonSizes;

  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String })
  readonly icon?: string;

  /**
   * A posição do ícone
   * @values left, right
   */
  @Prop({
    type: String,
    default: IconPosition.LEFT
  })
  readonly iconPosition!: IconPosition;

  /**
   * Define qual a superfície onde o botão está inserido
   * @values normal, overImage
   */
  @Prop({
    type: String,
    default: ButtonApplication.NORMAL
  })
  readonly appliedOn!: ButtonApplication;

  private get computedColors(): ButtonColors {
    switch (this.appliedOn) {
      case ButtonApplication.OVER_IMAGE:
        return primaryOverImage;
      case ButtonApplication.NORMAL:
      default:
        return primaryColors;
    }
  }

  private get computedClassName() {
    return [
      this.size,
      {
        [`icon-${this.iconPosition}`]: this.icon
      }
    ];
  }
}
</script>

<style lang="less" scoped>
.padding-with-icon(@value) {
  &.icon-left {
    padding-left: calc(@value - @size-spacing-x150);
  }

  &.icon-right {
    padding-right: calc(@value - @size-spacing-x150);
  }
}

.regular {
  @padding: @size-spacing-x500;
  padding-left: @padding;
  padding-right: @padding;
  min-width: 120px;

  .padding-with-icon(@padding);
}

.large {
  @padding: @size-spacing-x700;
  padding-left: @padding;
  padding-right: @padding;
  min-width: 160px;

  .padding-with-icon(@padding);
}
</style>
